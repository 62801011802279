import type { LocationSlug } from "../location/query.ts";
import type { HostPoolUUID, HostPoolWithType } from "./query.ts";

export function getLocationHostPools(pools: HostPoolWithType[], location: LocationSlug | undefined) {
    return pools.filter((s) => s.location === location);
}

export function getDefaultHostPool(pools: HostPoolWithType[], defaultHostPool: HostPoolUUID | null | undefined) {
    return pools.find((s) => s.uuid === defaultHostPool) ?? pools.find((s) => s.is_default_designated) ?? pools[0];
}
