import type { UseQueryResult, UseSuspenseQueryResult } from "@tanstack/react-query";
import { filterNulls } from "@warrenio/utils/collections/filterNulls";

/** Get the successful results from a combined query result from {@link useSuspenseQueries} or throw an error, if there is any. */
export function getQueriesData<T>(queryResults: (UseSuspenseQueryResult<T> | UseQueryResult<T>)[]) {
    for (const q of queryResults) {
        if (q.error) {
            throw q.error;
        }
    }

    return queryResults.filter((e) => e.isSuccess).map((e) => e.data!);
}

export interface QueryError {
    id: number | string;
    error: Error;
    refetch: () => void;
}

/** The data from a set of queries, with errors and loading status.
 *
 * The data is "streamed", ie. updated as soon as any of the queries return a result.
 */
export interface PartialQueriesData<T> {
    data: T;
    errors: QueryError[];
    /** Has at least one result been returned */
    isDataAvailable: boolean;
    /** Are there any queries still loading */
    isPending: boolean;
}

/** Stream query results into a {@link PartialQueriesData} */
export function getPartialQueriesData<T>(
    queryResults: (UseSuspenseQueryResult<T> | UseQueryResult<T>)[],
): PartialQueriesData<T[]> {
    const errors = filterNulls(
        queryResults.map(({ error, refetch }, index) => (error ? { id: index, error, refetch } : null)),
    );
    const data = queryResults.filter((e) => e.isSuccess).map((e) => e.data!);
    const isDataAvailable = queryResults.some((e) => e.isSuccess);
    const isPending = queryResults.some((e) => e.isPending);
    return { data, errors, isDataAvailable, isPending };
}

/** Run {@link Array.prototype.flatMap} on the data of a {@link PartialQueriesData} */
export function flatMapPartial<T, TResult>(
    result: PartialQueriesData<T[]>,
    fn: (data: T) => TResult[],
): PartialQueriesData<TResult[]> {
    return {
        ...result,
        data: result.data.flatMap(fn),
    };
}
