import { queryOptions, useSuspenseQueries, type QueryKey, type UseQueryOptions } from "@tanstack/react-query";
import type { HostPool } from "@warrenio/api-spec/spec.oats.gen";
import * as poolsSpec from "@warrenio/api-spec/spec/pools";
import { mapFromEntries } from "@warrenio/utils/collections/maps";
import { useAtomValue } from "jotai/react";
import { atom } from "jotai/vanilla";
import { getQueriesData } from "../../utils/query/getQueriesData.ts";
import { apiClientAtom } from "../api/apiClient.store.ts";
import { getResponseData, type ApiClient } from "../api/apiClient.ts";
import { locationsAtom } from "../api/locations.store.ts";

export interface Params {
    location: string;
}

export type HostPoolUUID = string;

export interface HostPoolWithType extends HostPool, Params {
    $type: "host_pool";
}

export type Response = Map<string, HostPoolWithType>;

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["user-resource/host_pool/list"] : ["user-resource/host_pool/list", params.location];
}

function hostPoolFromData(data: HostPool, location: string): HostPoolWithType {
    return { ...data, $type: "host_pool", location };
}

export function getSingleQuery(apiClient: ApiClient, params: Params): UseQueryOptions<Response> {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            const response = getResponseData(
                await apiClient.GET("/{location}/user-resource/host_pool/list", {
                    signal,
                    params: { path: { location } },
                }),
                import.meta.env.DEV && poolsSpec.HostPoolResponse,
            );
            return mapFromEntries(response, (pool: HostPool) => [pool.uuid, hostPoolFromData(pool, location)]);
        },
    });
}

export const getHostPoolsQueriesAtom = atom((get) => {
    //TODO: hetkel meil on testi tll tungsten ja jhv mikrotik me ei tõmbagi võrkudena sisse? See küll erand, aga tuleks läbi mõelda
    // praegu tekivad sellest duplikaadid
    const apiClient = get(apiClientAtom);
    return get(locationsAtom).map((location) => getSingleQuery(apiClient, { location }));
});

export function useSuspense() {
    const queries = useAtomValue(getHostPoolsQueriesAtom);
    return useSuspenseQueries({
        queries,
        combine(queryResults) {
            return getQueriesData(queryResults).flatMap((e) => [...e.values()]);
        },
    });
}
