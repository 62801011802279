import { Controller, type Control, type FieldValues, type Path } from "react-hook-form";
import { pricesAtom } from "../../modules/pricing/query.ts";
import { getIpAddressPrices, type IpAddressPrices } from "../../modules/pricing/resourcePricing.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { InfoTooltip } from "../WTooltip.tsx";
import { FormField } from "./FormField.tsx";
import { MonthlyCostElement } from "./MonthlyCostField.tsx";
import { WCheckbox } from "./WCheckbox.tsx";

export function CreateAndAssignIpField<TInputs extends FieldValues>({
    name,
    control,
    location,
}: {
    name: Path<TInputs>;
    control: Control<TInputs>;
    location: string;
}) {
    return (
        <FormField
            label="Public IP"
            description="When selected a public floating IPv4 address will be created and assigned automatically to your resource. If you do not want to have public access to your resource you can unselect the option here or remove the IP address later."
        >
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <WCheckbox
                        isDisabled={field.disabled}
                        isSelected={field.value}
                        onChange={field.onChange}
                        data-testid="createAndAssignIpCheckbox"
                    >
                        <div>
                            <div>Create a public IPv4 address for the resource</div>
                            <InlineIpCost location={location} />
                        </div>
                    </WCheckbox>
                )}
            />
        </FormField>
    );
}

function InlineIpCost({ location }: { location: string }) {
    const priceList = useSuspenseQueryAtom(pricesAtom);
    const prices = getIpAddressPrices(priceList, location);
    const anyNotFree = [prices.assigned, prices.unassigned].some((p) => p.hourly !== 0);
    return (
        anyNotFree && (
            <div className="inline-flex gap-1">
                <MonthlyCostElement price={prices.assigned} inline emphasize={false} />
                <InfoTooltip
                    wide
                    text={
                        <div className="flex gap-0.5rem">
                            <IpAddressPrices prices={prices} compact />
                        </div>
                    }
                />
            </div>
        )
    );
}

export function IpAddressPrices({ prices, compact }: { prices: IpAddressPrices; compact?: boolean }) {
    return (
        <>
            <MonthlyCostElement
                price={prices.assigned}
                title="Assigned:"
                compact={compact}
                emphasize={!compact}
                data-testid="assignedIpCost"
            />
            <MonthlyCostElement
                price={prices.unassigned}
                title="Unassigned:"
                compact={compact}
                emphasize={false}
                data-testid="unassignedIpCost"
            />
        </>
    );
}
